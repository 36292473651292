import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CharacterSheetContext = React.createContext([{}, () => {}]);

const CharacterSheetProvider = (props) => {
  const [state, setState] = useState({});
  const { children } = props;
  return (
    <CharacterSheetContext.Provider value={[state, setState]}>
      {children}
    </CharacterSheetContext.Provider>
  );
};

export { CharacterSheetContext, CharacterSheetProvider };

CharacterSheetProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
