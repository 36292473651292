import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ xs, sm, md, lg, xl }) => {
  const className = `spacer ${xs ? 'spacer-xs' : ''} ${
    sm ? 'spacer-sm' : ''
  } ${md ? 'spacer-md' : ''} ${lg ? 'spacer-lg' : ''} ${
    xl ? 'spacer-xl' : ''
  }`;

  return <div className={className} />;
};

Spacer.defaultProps = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
};

Spacer.propTypes = {
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
};

export default Spacer;
