import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const CommonTalents = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="common-talents">
      <legend>{t('commonTalents')}</legend>
      <FormGroup className="talents">
        <Label for="iconTalent" className="talent-name">
          {t('iconTalent')}
        </Label>
        <span className="talent-value">
          <Input
            type="text"
            name="iconTalent"
            id="iconTalent"
            value={t(data.iconTalent.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="talents">
        <Label for="groupTalent" className="talent-name">
          {t('groupTalent')}
        </Label>
        <span className="talent-value">
          <Input
            type="text"
            name="groupTalent"
            id="groupTalent"
            value={t(data.groupTalent.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="talents">
        <Label for="personalTalents" className="talent-name">
          {t('personalTalents')}
        </Label>
        <span className="talent-value">
          <Input
            type="text"
            name="personalTalents"
            id="personalTalents"
            value={data.personalTalents.map((pt) => t(pt.id))}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

CommonTalents.propTypes = {
  data: PropTypes.shape({
    groupTalent: PropTypes.shape({ id: PropTypes.string.isRequired })
      .isRequired,
    personalTalents: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ).isRequired,
    iconTalent: PropTypes.shape({ id: PropTypes.string.isRequired })
      .isRequired,
    humaniteTalent: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    cyberneticImplants: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    bionicSculpts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    mysticalPowers: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
  }).isRequired,
};

export default CommonTalents;
