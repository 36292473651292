import fetch from 'node-fetch';

export const getCharacter = async (characterId) => {
  const response = await fetch(
    `/.netlify/functions/character-read/${characterId}`,
  ).then((r) => r);
  return response.json();
};

export const roll = async (diceToRoll) => {
  const result = await fetch(
    `/.netlify/functions/roll/${diceToRoll}`,
  ).then((response) => {
    if (!response.ok) {
      throw new Error(`Failed to roll ${diceToRoll}`);
    }
    return response;
  });
  return result.json();
};
