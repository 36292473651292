import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Background = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="background-data">
      <legend>{t('background')}</legend>
      <FormGroup className="background">
        <Label for="concept" className="background-name">
          {t('concept')}
        </Label>
        <span className="background-value">
          <Input
            type="text"
            name="concept"
            id="concept"
            value={t(data.concept.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="background">
        <Label for="subConcept" className="background-name">
          {t('subConcept')}
        </Label>
        <span className="background-value">
          <Input
            type="text"
            name="subConcept"
            id="subCconcept"
            value={t(data.subConcept.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="background">
        <Label for="lineage" className="background-name">
          {t('lineage')}
        </Label>
        <span className="background-value">
          <Input
            type="text"
            name="lineage"
            id="lineage"
            value={t(data.origin.lineage.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="background">
        <Label for="starSystem" className="background-name">
          {t('starSystem')}
        </Label>
        <span className="background-value">
          <Input
            type="text"
            name="starSystem"
            id="starSystem"
            value={t(data.origin.starSystem.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="background">
        <Label for="uppbringing" className="background-name">
          {t('upbringing')}
        </Label>
        <span className="background-value">
          <Input
            type="text"
            name="upbringing"
            id="upbringing"
            value={t(data.origin.upbringing.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="background">
        <Label for="icon" className="background-name">
          {t('icon')}
        </Label>
        <span className="background-value">
          <Input
            type="text"
            name="icon"
            id="icon"
            value={t(data.origin.icon.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="background">
        <Label for="personalProblem" className="background-name">
          {t('personalProblem')}
        </Label>
        <span className="background-value">
          <Input
            type="textarea"
            name="personalProblem"
            id="personalProblem"
            value={t(data.personalProblem.id)}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Background.propTypes = {
  data: PropTypes.shape({
    concept: PropTypes.shape({ id: PropTypes.string.isRequired }),
    subConcept: PropTypes.shape({ id: PropTypes.string.isRequired }),
    origin: PropTypes.shape({
      lineage: PropTypes.shape({ id: PropTypes.string.isRequired }),
      starSystem: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      upbringing: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      icon: PropTypes.shape({ id: PropTypes.string.isRequired }),
    }),
    personalProblem: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Background;
