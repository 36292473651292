import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const formatRating = (rating) => (rating === 0 ? '' : rating);

const Armor = ({ breakpoint, data }) => {
  const { t } = useTranslation();

  return (
    <div className="armor-data">
      <legend>{t('armor')}</legend>
      <Table responsive={breakpoint}>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('rating')}</th>
            <th>{t('comment')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.name}</td>
            <td>{formatRating(data.rating.current)}</td>
            <td>{data.comment}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

Armor.defaultProps = {
  breakpoint: '',
  data: {
    name: '',
    rating: {
      current: 0,
      base: 0,
    },
    comment: '',
  },
};

Armor.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    rating: PropTypes.shape({
      current: PropTypes.number.isRequired,
      base: PropTypes.number.isRequired,
    }).isRequired,
    comment: PropTypes.string,
  }),
};

export default Armor;
