import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Cabin = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="cabin-data">
      <legend>{t('cabin')}</legend>
      <FormGroup className="cabin">
        <Label for="description" className="cabin-name">
          {t('description')}
        </Label>
        <span className="cabin-value">
          <Input
            type="textarea"
            name="description"
            id="description"
            value={data.description}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="cabin">
        <Label for="gear" className="cabin-name">
          {t('gear')}
        </Label>
        <span className="cabin-value">
          <Input
            type="textarea"
            name="gear"
            id="gear"
            value={data.gear}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="cabin">
        <Label for="other" className="cabin-name">
          {t('other')}
        </Label>
        <span className="cabin-value">
          <Input
            type="textarea"
            name="other"
            id="other"
            value={data.other}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Cabin.defaultProps = {
  data: {
    description: '',
    gear: '',
    other: '',
  },
};

Cabin.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    gear: PropTypes.string,
    other: PropTypes.string,
  }),
};

export default Cabin;
