export default {
  coriolis: 'Coriolis',
  swedish: 'Swedish',
  english: 'English',

  attributes: 'Attributes',
  strength: 'Strength',
  agility: 'Agility',
  wits: 'Wits',
  empathy: 'Empathy',

  trauma: 'Trauma',
  hitPoints: 'Hit Points',
  criticalInjuries: 'Critical Injuries',
  mindPoints: 'Mind Points',

  radiation: 'Radiation',
  currentRadiation: 'Current Radiation',
  permanentRadiation: 'Permanent Radiation',

  experience: 'Experience',

  generalSkills: 'General Skills',
  dexterity: 'Dexterity',
  force: 'Force',
  infiltration: 'Infiltration',
  manipulation: 'Manipulation',
  meleeCombat: 'Meleee Combat',
  observation: 'Observation',
  rangedCombat: 'Ranged Combat',
  survival: 'Survival',

  advancedSkills: 'Advanced Skills',
  command: 'Command',
  culture: 'Culture',
  dataDjinn: 'Data Djinn',
  medicurgy: 'Medicurgy',
  mysticPowers: 'Mystic Powers',
  pilot: 'Pilot',
  science: 'Science',
  technology: 'Technology',

  commonTalents: 'Common Talents',
  iconTalent: 'Icon Talent',
  groupTalent: 'Group Talent',
  personalTalents: 'Personal Talent',

  theTravelersTalent: 'The Traveler’s Talent',

  seasonedTravelers: 'Seasoned Travelers',

  nineLives: 'Nine Lives',

  specialTalents: 'Special Talents',
  noSpecialTalents: 'No special talents.',
  humaniteTalent: 'Humanite Talent',
  cyberneticImplants: 'Cybernetic Implants',
  bionicScuplts: 'Bionic Sculpts',
  mysticalPowers: 'Mystical Powers',

  background: 'Background',
  concept: 'Concept',
  subConcept: 'Sub-Concept',
  lineage: 'Lineage',
  starSystem: 'Star System',
  upbringing: 'Upbringing',
  icon: 'Icon',
  personalProblem: 'Personal Problem',
  reputation: 'Reputation',

  trailblazer: 'Trailblazer',
  prospector: 'Prospector',
  firstcome: 'Firstcome',
  kua: 'Kua',
  stationary: 'Stationary',
  theTraveler: 'The Traveler',
  possessed:
    'You are possessed by something from the Dark between the Stars. It sometimes takes over, giving you seizures or memory loss.',

  appearance: 'Appearance',
  face: 'Face',
  clothing: 'Clothing',

  group: 'Group',
  groupConcept: 'Group Concept',
  groupSubConcept: 'Group Sub-Concept',

  explorers: 'Explorers',
  prospectors: 'Prospectors',

  relationships: 'Relationships',

  armor: 'Armor',
  name: 'Name',
  rating: 'Rating',
  comment: 'Comment',

  weapons: 'Weapons',
  bonus: 'Bonus',
  init: 'Init',
  damage: 'Damage',
  crit: 'Crit',
  range: 'Range',
  comments: 'Comments',
  reloads: 'Reloads',

  short: 'Short',

  gear: 'Gear',

  people: 'People I’ve Met',

  cabin: 'My Cabin',
  description: 'Description',
  other: 'Other',

  itemsTitle: 'Tiny Items',
  items: 'Items',
  birr: 'Birr',

  notes: 'Notes',
};
