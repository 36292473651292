import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const SpecialTalents = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="special-talents">
      <legend>{t('specialTalents')}</legend>
      {!data.humaniteTalent &&
      !data.cyberneticImplants &&
      !data.bionicSculpts &&
      !data.mysticalPowers ? (
        <p>{t('noSpecialTalents')}</p>
      ) : null}
      {data.humaniteTalent && (
        <FormGroup className="talents">
          <Label for="humaniteTalent" className="talent-name">
            {t('humaniteTalent')}
          </Label>
          <span className="talent-value">
            <Input
              type="text"
              name="humaniteTalent"
              id="humaniteTalent"
              value={t(data.humaniteTalent.id)}
              disabled
            />
          </span>
        </FormGroup>
      )}
      {/* {data.personalTalents.map((pt) => t(pt.id))} */}
      {data.cyberneticImplants && (
        <FormGroup className="talents">
          <Label for="cyberneticImplants" className="talent-name">
            {t('cyberneticImplants')}
          </Label>
          <span className="talent-value">
            <Input
              type="text"
              name="cyberneticImplants"
              id="cyberneticImplants"
              value={data.cyberneticImplants.map((ci) => t(ci.id))}
              disabled
            />
          </span>
        </FormGroup>
      )}
      {data.bionicSculpts && (
        <FormGroup className="talents">
          <Label for="bionicSculpts" className="talent-name">
            {t('bionicScupts')}
          </Label>
          <span className="talent-value">
            <Input
              type="text"
              name="bionicSculpts"
              id="bionicSculpts"
              value={data.bionicSculpts.map((bs) => t(bs.id))}
              disabled
            />
          </span>
        </FormGroup>
      )}
      {data.mysticalPowers && (
        <FormGroup className="talents">
          <Label for="mysticalPowers" className="talent-name">
            {t('mysticalPowers')}
          </Label>
          <span className="talent-value">
            <Input
              type="text"
              name="mysticalPowers"
              id="mysticalPowers"
              value={data.mysticalPowers.map((mp) => t(mp.id))}
              disabled
            />
          </span>
        </FormGroup>
      )}
    </div>
  );
};

SpecialTalents.propTypes = {
  data: PropTypes.shape({
    groupTalent: PropTypes.shape({ id: PropTypes.string.isRequired })
      .isRequired,
    personalTalents: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ).isRequired,
    iconTalent: PropTypes.shape({ id: PropTypes.string.isRequired })
      .isRequired,
    humaniteTalent: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    cyberneticImplants: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    bionicSculpts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    mysticalPowers: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
  }).isRequired,
};

export default SpecialTalents;
