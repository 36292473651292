// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Exo:wght@531&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html {\n  background-color: rgb(27, 27, 28);\n}\n\n@media (min-width: 768px) {\n  html {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center fixed;\n    -webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n  }\n}\n\nbody {\n  font-family: 'Exo', sans-serif;\n  color: white;\n  background-color: transparent;\n}\n\nh1 {\n  margin-top: 2rem;\n  text-align: center;\n}\n\n.table {\n  color: white;\n}\n\n.table thead th {\n  border-bottom: 2px solid currentColor;\n}\n\n.table th,\n.table td {\n  border-top: 1px solid currentColor;\n}\n\n/*\nrgb(27, 27, 28)\nrgb(73, 131, 179)\nrgb(167, 181, 197)\nrgb(94, 81, 86)\nrgb(41, 72, 106)\nrgb(38, 47, 65)\nrgb(64, 54, 60)\nrgb(114, 132, 136)\nrgb(65, 85, 81)\nrgb(52, 62, 50)\n*/\n", ""]);
// Exports
module.exports = exports;
