import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Group = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="group-data">
      <legend>{t('group')}</legend>
      <FormGroup className="group">
        <Label for="groupConcept" className="group-name">
          {t('groupConcept')}
        </Label>
        <span className="group-value">
          <Input
            type="text"
            name="groupConcept"
            id="groupConcept"
            value={t(data.concept.id)}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="group">
        <Label for="groupSubConcept" className="group-name">
          {t('groupSubConcept')}
        </Label>
        <span className="group-value">
          <Input
            type="text"
            name="groupSubConcept"
            id="groupSubConcept"
            value={t(data.subConcept.id)}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Group.propTypes = {
  data: PropTypes.shape({
    concept: PropTypes.shape({ id: PropTypes.string.isRequired })
      .isRequired,
    subConcept: PropTypes.shape({ id: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
};

export default Group;
