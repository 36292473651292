/* eslint-disable react/jsx-filename-extension */
/* global document */

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

import './internationalization/i18n';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.css';

ReactDOM.render(
  <Suspense fallback={null}>
    <App />
  </Suspense>,
  document.getElementById('app'),
);

if (module.hot) {
  module.hot.accept();
}
