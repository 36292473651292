import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CharacterSheet from './CharacterSheetContainer';
import RollDice from './RollDice';

const App = () => {
  const { t } = useTranslation();
  return (
    <Router>
      <Switch>
        <Route
          path="/"
          exact
          render={() => <h1>{t('coriolis')}</h1>}
        />
        <Route path="/character/:id" component={CharacterSheet} />
        <Route path="/roll/:diceToRoll" component={RollDice} />
        <Route render={() => <h1>404: page not found</h1>} />
      </Switch>
    </Router>
  );
};

export default App;
