import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useCharacterSheet from '../hooks/useCharacterSheet';

const Traumas = ({ data }) => {
  const { t } = useTranslation();
  const { hitPoints = 1, setHitPoints } = useCharacterSheet();

  return (
    <div className="traumas">
      <legend>{t('trauma')}</legend>
      <FormGroup className="trauma">
        <Label for="hitPoints" className="trauma-name">
          {t('hitPoints')}
        </Label>
        <span className="trauma-value">
          <Input
            type="number"
            name="hitPoints"
            id="hitPoints"
            min="1"
            max="99"
            // value={
            //   data.attributes.find(
            //     (attribute) => attribute.id === 'strength',
            //   ).current +
            //   data.attributes.find(
            //     (attribute) => attribute.id === 'agility',
            //   ).current +
            //   data.trauma.hitPoints.modifier
            // }
            value={hitPoints}
            onChange={(event) => {
              setHitPoints(event.target.value);
            }}
          />
        </span>
      </FormGroup>
      <FormGroup>
        <Label for="criticalInjuries">{t('criticalInjuries')}</Label>
        <Input
          type="textarea"
          name="criticalInjuries"
          id="criticalInjuries"
          value={data.trauma.criticalInjuries}
          disabled
        />
      </FormGroup>
      <FormGroup className="trauma">
        <Label for="mindPoints" className="trauma-name">
          {t('mindPoints')}
        </Label>
        <span className="trauma-value">
          <Input
            type="number"
            name="mindPoints"
            id="mindPoints"
            min="1"
            max="99"
            value={
              data.attributes.find(
                (attribute) => attribute.id === 'wits',
              ).current +
              data.attributes.find(
                (attribute) => attribute.id === 'empathy',
              ).current +
              data.trauma.mindPoints.modifier
            }
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Traumas.propTypes = {
  data: PropTypes.shape({
    player: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        base: PropTypes.number.isRequired,
        current: PropTypes.number.isRequired,
      }),
    ).isRequired,
    trauma: PropTypes.shape({
      hitPoints: PropTypes.shape({
        modifier: PropTypes.number.isRequired,
        current: PropTypes.number.isRequired,
      }),
      criticalInjuries: PropTypes.string.isRequired,
      mindPoints: PropTypes.shape({
        modifier: PropTypes.number.isRequired,
        current: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
};

export default Traumas;
