import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Items = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="items-data">
      <legend>{t('itemsTitle')}</legend>
      <FormGroup className="items">
        <Label for="items" className="items-name">
          {t('items')}
        </Label>
        <span className="items-value">
          <Input
            type="textarea"
            name="items"
            id="items"
            value={data.items}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="birr">
        <Label for="birr" className="birr-name">
          {t('birr')}
        </Label>
        <span className="birr-value">
          <Input
            type="number"
            name="birr"
            id="birr"
            min="1"
            max="9999"
            value={data.birr}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Items.defaultProps = {
  data: {
    items: '',
    birr: 0,
  },
};

Items.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.string,
    birr: PropTypes.number,
  }),
};

export default Items;
