import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Experience = ({ experience }) => {
  const { t } = useTranslation();

  return (
    <div className="experience-data">
      <legend>{t('experience')}</legend>
      <FormGroup className="experience">
        <Label for="experience" className="experience-name">
          {t('experience')}
        </Label>
        <span className="experience-value">
          <Input
            type="number"
            name="experience"
            id="experience"
            min="1"
            max="99"
            value={experience}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Experience.propTypes = {
  experience: PropTypes.number.isRequired,
};

export default Experience;
