import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'reactstrap';

const ChangeLanguage = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  return (
    <div className="clearfix">
      <ButtonGroup className="float-right">
        <Button
          type="button"
          onClick={() => changeLanguage('sv')}
          active={i18n.language === 'sv'}
        >
          {t('swedish')}
        </Button>
        <Button
          type="button"
          onClick={() => changeLanguage('en')}
          active={i18n.language === 'en'}
        >
          {t('english')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ChangeLanguage;
