import { useContext } from 'react';

import { CharacterSheetContext } from '../components/CharacterSheetContext';

const useCharacterSheet = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(CharacterSheetContext);

  function setHitPoints(hp) {
    setState((s) => {
      return { ...s, hitPoints: hp };
    });
  }

  return {
    hitPoints: state.hitPoints,
    setHitPoints,
  };
};

export default useCharacterSheet;
