import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const formatBonus = (bonus) => {
  if (bonus > 0) {
    return `+${bonus}`;
  }
  return `${bonus}`;
};

const Gear = ({ breakpoint, data }) => {
  const { t } = useTranslation();

  return (
    <div className="gear-data">
      <legend>{t('gear')}</legend>
      <Table responsive={breakpoint}>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('bonus')}</th>
            <th>{t('comment')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((gear) => (
            <tr key={gear.name}>
              <td>{gear.name}</td>
              <td>{formatBonus(gear.bonus.current)}</td>
              <td>{gear.comment}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

Gear.defaultProps = {
  breakpoint: '',
  data: [],
};

Gear.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      bonus: PropTypes.shape({
        current: PropTypes.number.isRequired,
        base: PropTypes.number.isRequired,
      }).isRequired,
      comment: PropTypes.string,
    }),
  ),
};

export default Gear;
