import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';
import { useQuery /* useMutation, queryCache */ } from 'react-query';
import { Container, Row, Col, Form } from 'reactstrap';

import Attributes from './Attributes';
import Traumas from './Traumas';
import Background from './Background';
import Group from './Group';
import { getCharacter } from '../utils/api';
import '../styles/character-sheet.css';
import Appearance from './Appearance';
import Relationships from './Relationships';
import Radiation from './Radiation';
import CommonTalents from './CommonTalents';
import SpecialTalents from './SpecialTalents';
import GeneralSkills from './GeneralSkills';
import AdvancedSkills from './AdvancedSkills';
import Experience from './Experience';
import Armor from './Armor';
import Weapons from './Weapons';
import Gear from './Gear';
import People from './People';
import Cabin from './Cabin';
import Items from './Items';
import Notes from './Notes';
import Spacer from './Spacer';
import Reputation from './Reputation';

const CharacterSheetContent = ({ id }) => {
  // Interval for auto refetch
  const intervalMs = 3600000;

  // Get character data
  const { isLoading, isError, data } = useQuery(id, getCharacter, {
    // Refetch the data every second
    refetchInterval: intervalMs,
  });

  // Manage states without data
  if (isLoading) {
    return <h1>Loading Character Data…</h1>;
  }
  if (isError) {
    return <Redirect to="/" />;
  }

  // Redirect to frontpage if character data doesn’t exist
  if (!data) {
    return <Redirect to="/" />;
  }

  const { data: characterData } = data;

  return (
    <Form>
      <Container>
        <Row>
          <Col>
            <h1>{characterData.name}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="12">
            <Attributes data={characterData.attributes} />
            <Experience experience={characterData.experience} />
            <Reputation data={characterData.reputation} />
          </Col>
          <Col md="4" sm="12">
            <GeneralSkills
              data={characterData.skills.generalSkills}
            />
          </Col>
          <Col md="4" sm="12">
            <AdvancedSkills
              data={characterData.skills.advancedSkills}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <CommonTalents data={characterData.talents} />
          </Col>
          <Col md="6" sm="12">
            <SpecialTalents data={characterData.talents} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="12">
            <Traumas data={characterData} />
            <Radiation data={characterData.radiation} />
          </Col>
          <Col lg="9" sm="12">
            <Weapons breakpoint="md" data={characterData.weapons} />
            <Armor breakpoint="md" data={characterData.armor} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Gear breakpoint="md" data={characterData.gear} />
          </Col>
          <Col lg="6" sm="12">
            <Items data={characterData.items} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <Background data={characterData.background} />
          </Col>
          <Col md="6" sm="12">
            <Appearance data={characterData.appearance} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <Relationships data={characterData.relationships} />
          </Col>
          <Col md="6" sm="12">
            <Group data={characterData.group} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col>
            <People breakpoint="md" data={characterData.people} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col>
            <Cabin data={characterData.cabin} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer />
          </Col>
        </Row>
        <Row>
          <Col>
            <Notes data={characterData.notes} />
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

CharacterSheetContent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CharacterSheetContent;
