export default {
  coriolis: 'Coriolis',
  swedish: 'Svenska',
  english: 'Engelska',

  attributes: 'Grundegenskaper',
  strength: 'Styrka',
  agility: 'Kyla',
  wits: 'Skärpa',
  empathy: 'Känsla',

  trauma: 'Trauma',
  hitPoints: 'Kroppspoäng',
  criticalInjuries: 'Kritiska skador',
  mindPoints: 'Stresspoäng',

  radiation: 'Strålningspoäng',
  currentRadiation: 'Aktuella strålningspoäng',
  permanentRadiation: 'Permanenta strålningspoäng',

  experience: 'Erfarenhet',

  generalSkills: 'Allmänna färdigheter',
  dexterity: 'Rörlighet',
  force: 'Kraftprov',
  infiltration: 'Smyga',
  manipulation: 'Manipulera',
  meleeCombat: 'Närkamp',
  observation: 'Spaning',
  rangedCombat: 'Skjutvapen',
  survival: 'Överlevnad',

  advancedSkills: 'Kvalificerade färdigheter',
  command: 'Befäl',
  culture: 'Horisontens kulturer',
  dataDjinn: 'Datadjinn',
  medicurgy: 'Medikurgi',
  mysticPowers: 'Mystiska krafter',
  pilot: 'Pilot',
  science: 'Vetenskap',
  technology: 'Teknologi',

  commonTalents: 'Allmänna talanger',
  iconTalent: 'Ikontalang',
  groupTalent: 'Grupptalang',
  personalTalents: 'Egna talanger',

  theTravelersTalent: 'Resenärens talang',

  seasonedTravelers: 'Resvana',

  nineLives: 'Nio liv',

  specialTalents: 'Särskilda talanger',
  noSpecialTalents: 'Inga särskilda talanger.',
  humaniteTalent: 'Humanitisk talang',
  cyberneticImplants: 'Cybernetic implantat',
  bionicSculpts: 'Bioniska skuplter',
  mysticalPowers: 'Mystiska talanger',

  background: 'Bakgrund',
  concept: 'Koncept',
  subConcept: 'Underkoncept',
  lineage: 'Härkomst',
  starSystem: 'Hemsystem',
  upbringing: 'Uppväxt',
  icon: 'Ikon',
  personalProblem: 'Personligt problem',
  reputation: 'Anseende',

  trailblazer: 'Explorator',
  prospector: 'Prospektör',
  firstcome: 'Förstkommen',
  kua: 'Kua',
  stationary: 'Stationär',
  theTraveler: 'Resenären',
  possessed:
    'Du är besatt av något från mörkret mellan stjärnorna. Ibland tar det över dig och du får kramper eller minnesluckor.',

  appearance: 'Utseende',
  face: 'Ansikte',
  clothing: 'Klädsel',

  group: 'Grupp',
  groupConcept: 'Gruppkoncept',
  groupSubConcept: 'Gruppunderkoncept',

  explorers: 'Utforskare',
  prospectors: 'Prospektörer',

  relationships: 'Relationer',

  armor: 'Rustning',
  name: 'Namn',
  rating: 'Skydd',
  comment: 'Övrigt',

  weapons: 'Vapen',
  bonus: 'Bonus',
  init: 'Init',
  damage: 'Skada',
  crit: 'Krit',
  range: 'Räckvidd',
  comments: 'Övrigt',
  reloads: 'Omladdning',

  short: 'Kort',

  gear: 'Utrustning',

  people: 'Personer jag mött',

  cabin: 'Min hytt',
  description: 'Beskrivning',
  other: 'Övrigt',

  titleItems: 'Prylar',
  items: 'Småprylar',
  birr: 'Birr',

  notes: 'Anteckningar',
};
