import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const label = (name, buddy) => `${name} ${buddy ? '*' : ''}`;

const Relationships = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="group-data">
      <legend>{t('relationships')}</legend>
      {data.map((relationship) => {
        const { name, description, buddy } = relationship;
        return (
          <FormGroup
            className="relationship"
            key={`relationship-${name}`}
          >
            <Label
              for={`relationship-${name}`}
              className="group-name"
            >
              {label(name, buddy)}
            </Label>
            <span className="group-value">
              <Input
                type="textarea"
                name={`relationship-${name}`}
                id={`relationship-${name}`}
                value={description}
                disabled
              />
            </span>
          </FormGroup>
        );
      })}
    </div>
  );
};

Relationships.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buddy: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
};

export default Relationships;
