import React from 'react';

import { Redirect, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { roll } from '../utils/api';

const RollDice = () => {
  const { diceToRoll } = useParams();

  const { isLoading, isError, data } = useQuery(diceToRoll, roll);

  if (isLoading) {
    return <h1>Rolling Dice…</h1>;
  }
  if (isError) {
    return <Redirect to="/" />;
  }

  if (!data) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <h2>Dice To Roll</h2>
      <p>{data.diceToRoll}</p>
      <h2>Result</h2>
      <p>{data.result.toString()}</p>
      <h2>Sum</h2>
      <p>{data.sum}</p>
    </div>
  );
};

export default RollDice;
