import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Radiation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="radiations">
      <legend>{t('radiation')}</legend>
      <FormGroup className="radiation">
        <Label for="currentRadiation" className="radiation-name">
          {t('currentRadiation')}
        </Label>
        <span className="radiation-value">
          <Input
            type="number"
            name="currentRadiation"
            id="currentRadiation"
            min="1"
            max="99"
            value={data.currentRadiation}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="radiation">
        <Label for="permanentRadiation" className="radiation-name">
          {t('permanentRadiation')}
        </Label>
        <span className="radiation-value">
          <Input
            type="number"
            name="permanentRadiation"
            id="permanentRadiation"
            min="1"
            max="99"
            value={data.permanentRadiation}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Radiation.propTypes = {
  data: PropTypes.shape({
    currentRadiation: PropTypes.number.isRequired,
    permanentRadiation: PropTypes.number.isRequired,
  }).isRequired,
};

export default Radiation;
