import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const People = ({ breakpoint, data }) => {
  const { t } = useTranslation();

  return (
    <div className="people-data">
      <legend>{t('people')}</legend>
      <Table responsive={breakpoint}>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('concept')}</th>
            <th>{t('comments')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((people) => (
            <tr key={people.name}>
              <td>{people.name}</td>
              <td>{people.concept}</td>
              <td>{people.comments}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

People.defaultProps = {
  breakpoint: '',
  data: [],
};

People.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      concept: PropTypes.string,
      comments: PropTypes.string,
    }),
  ),
};

export default People;
