import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Attributes = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="attributes">
      <legend>{t('attributes')}</legend>
      {data.map((attribute) => {
        const { id, current } = attribute;
        return (
          <FormGroup className="attribute" key={`attribute-${id}`}>
            <Label for={`attribute-${id}`} className="attribute-name">
              {t(id)}
            </Label>
            <span className="attribute-value">
              <Input
                type="number"
                name="strength"
                id="strength"
                min="1"
                max="99"
                value={current}
                disabled
              />
            </span>
          </FormGroup>
        );
      })}
    </div>
  );
};

Attributes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      base: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default Attributes;
