import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Appearance = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="appearance-data">
      <legend>{t('appearance')}</legend>
      <FormGroup className="appearance">
        <Label for="face" className="appearance-name">
          {t('face')}
        </Label>
        <span className="appearance-value">
          <Input
            type="textarea"
            name="face"
            id="face"
            value={data.face}
            disabled
          />
        </span>
      </FormGroup>
      <FormGroup className="appearance">
        <Label for="clothing" className="appearance-name">
          {t('clothing')}
        </Label>
        <span className="appearance-value">
          <Input
            type="textarea"
            name="clothing"
            id="clothing"
            value={data.clothing}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Appearance.propTypes = {
  data: PropTypes.shape({
    face: PropTypes.string.isRequired,
    clothing: PropTypes.string.isRequired,
  }).isRequired,
};

export default Appearance;
