import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const formatBonus = (bonus) => {
  if (bonus > 0) {
    return `+${bonus}`;
  }
  return `${bonus}`;
};

const Weapons = ({ breakpoint, data }) => {
  const { t } = useTranslation();

  return (
    <div className="weapons-data">
      <legend>{t('weapons')}</legend>
      <Table responsive={breakpoint}>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('bonus')}</th>
            <th>{t('init')}</th>
            <th>{t('damage')}</th>
            <th>{t('crit')}</th>
            <th>{t('range')}</th>
            <th>{t('comments')}</th>
            <th>{t('reloads')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((weapon) => (
            <tr key={weapon.name}>
              <td>{weapon.name}</td>
              <td>{formatBonus(weapon.bonus.current)}</td>
              <td>{weapon.initiative.current}</td>
              <td>{weapon.damage.current}</td>
              <td>{weapon.critRating.current}</td>
              <td>{t(weapon.range.id)}</td>
              <td>{weapon.comments}</td>
              <td>{weapon.reloads.current}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

Weapons.defaultProps = {
  breakpoint: '',
  data: [],
};

Weapons.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      bonus: PropTypes.shape({
        current: PropTypes.number.isRequired,
        base: PropTypes.number.isRequired,
      }).isRequired,
      initiative: PropTypes.shape({
        current: PropTypes.number.isRequired,
        base: PropTypes.number.isRequired,
      }).isRequired,
      damage: PropTypes.shape({
        current: PropTypes.number.isRequired,
        base: PropTypes.number.isRequired,
      }).isRequired,
      critRating: PropTypes.shape({
        current: PropTypes.number.isRequired,
        base: PropTypes.number.isRequired,
      }).isRequired,
      range: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      comments: PropTypes.string,
      reloads: PropTypes.number.isRequired,
    }),
  ),
};

export default Weapons;
