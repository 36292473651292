import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Notes = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="notes-data">
      <legend>{t('notes')}</legend>
      <FormGroup className="notes">
        <Label for="items" className="notes-name">
          {t('notes')}
        </Label>
        <span className="notes-value">
          <Input
            type="textarea"
            name="notes"
            id="notes"
            value={data.notes}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Notes.defaultProps = {
  data: {
    notes: '',
  },
};

Notes.propTypes = {
  data: PropTypes.shape({
    notes: PropTypes.string,
  }),
};

export default Notes;
