import React from 'react';

import { useParams, Redirect } from 'react-router-dom';

import validId from '../utils/validate';

import { CharacterSheetProvider } from './CharacterSheetContext';
import CharacterSheetContent from './CharacterSheetContent';
import ChangeLanguage from './ChangeLanguage';

const CharacterSheetContainer = () => {
  // Get id from path
  const { id } = useParams();

  // Validate id
  if (!validId(id)) {
    return <Redirect to="/" />;
  }

  return (
    <CharacterSheetProvider>
      <ChangeLanguage />
      <CharacterSheetContent id={id} />
    </CharacterSheetProvider>
  );
};

export default CharacterSheetContainer;
