import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Reputation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="reputation-data">
      <legend>{t('reputation')}</legend>
      <FormGroup className="reputation">
        <Label for="reputation" className="reputation-name">
          {t('reputation')}
        </Label>
        <span className="reputation-value">
          <Input
            type="number"
            name="reputation"
            id="reputation"
            min="1"
            max="99"
            value={data.current}
            disabled
          />
        </span>
      </FormGroup>
    </div>
  );
};

Reputation.propTypes = {
  data: PropTypes.shape({
    current: PropTypes.number.isRequired,
    base: PropTypes.number.isRequired,
  }).isRequired,
};

export default Reputation;
