import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const label = (t, id, attribute) => `${t(id)} (${t(attribute)})`;

const GeneralSkills = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="skills">
      <legend>{t('generalSkills')}</legend>
      {data.map((skill) => {
        const { id, attribute, current } = skill;
        return (
          <FormGroup className="skill" key={`skill-${id}`}>
            <Label
              for={`skill-${id}`}
              className="skill-name text-truncate text-nowrap"
            >
              {label(t, id, attribute)}
            </Label>
            <span className="skill-value">
              <Input
                type="number"
                name={`skill-${id}`}
                id={`skill-${id}`}
                min="1"
                max="99"
                value={current}
                disabled
              />
            </span>
          </FormGroup>
        );
      })}
    </div>
  );
};

GeneralSkills.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attribute: PropTypes.string.isRequired,
      base: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default GeneralSkills;
